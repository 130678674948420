import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link } from "gatsby"
import styled from 'styled-components'
import { transparentize, readableColor } from 'polished'
import { config, useSpring, animated } from 'react-spring'
import Layout from '../components/layout'
import { Box, AnimatedBox, Button, Input, TextArea, Form, Label } from '../elements'
import SEO from '../components/SEO'

const Contact = (props) => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const PBox = styled(AnimatedBox)`
  max-width: 1400px;
  margin: 0 auto;
  
`

  const PButton = styled(Button)<{ color: string }>`
  background: ${props => (props.color === 'white' ? 'black' : props.color)};
  color: ${props => readableColor(props.color === 'white' ? 'black' : props.color)};
`
/*Bouton formulaire*/
const FButton = styled(Button)`
  max-width:250px;
  magin:auto;
  grid-column: 2;
  grid-row: auto;
  background: black;
  color: white;

  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    grid-column: 1;
    margin: 0.8em;
    max-width:100%;
  }
`
/*Image formulaire*/
const FImg = styled(Img)`
  grid-column: 3;
  grid-row: span 6;
  max-height: calc(70%);
  margin: 1em;
  margin-top: 0.5em;
  max-width: 500px;

  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    grid-column: 1;
  }
`
/*Image Description*/
const DImg = styled(Img)`
  max-height: calc(70%);
  margin: 1em;
  margin-top: 0.5em;
  max-width: 500px;

  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    grid-column: 1;
  }
`

const Fdiv = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  grid-auto-flow: row dense;
  grid-gap: 2em;
  padding: 1.2em;

  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    grid-template-columns: 1fr;
    grid-gap: .2em;
  }
`


  return (
    <Layout>
      <SEO title="Contact | Marcellin" desc="Hi. I'm Marcellin Piguet! You can also visit my instagram account." />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 6, 6]}>
        
          <h1>Leave me a message 😉</h1>
        
          <Form name="contact" method="post" data-netlify="true" netlify-honeypot="bot-field" action="/success">
              <input type="hidden" name="form-name" value="contact" />
              <input type="hidden" name="bot-field" />
              <Label>Name</Label>
              <Input type="text" name="name" id="name"/>
              <Label> email</Label>
              <Input type="email" name="email" id="email" />
              <Label>Subject</Label>
              <Input type="text" name="subject" id="subject" />
                <Label>Message</Label>
                <TextArea name="message" id="message" rows="5" />
              <FButton padding={"15px"} type="submit">Send my message</FButton>
            </Form>
      </AnimatedBox>
    </Layout>
  )
}

export default Contact

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 700, maxHeight: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "form_img.jpg" }) {
      ...fluidImage
    }
    imageTwo: file(relativePath: { eq: "marcellin.jpg" }) {
      ...fluidImage
    }
  }
`
